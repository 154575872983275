<template>
  <div v-if="show && isMobile" class="overlay" @click="$emit('overlay-click')">
    <div class="text-container">
      <p class="modal-info-title">
        El menú te permite filtrar entre:
      </p>
      <br />
      <span class="modal-info-subtitle">Local/visitante:&nbsp;</span>
      <span class="modal-info-text"
        >Muestra datos considerando únicamente los partidos en los que el equipo ha jugado en la misma condición en la
        que se va a dar el partido (como local o visitante) en el torneo.</span
      >
      <br /><br />
      <span class="modal-info-subtitle">Todos los partidos:&nbsp;</span>
      <span class="modal-info-text"
        >Muestra datos considerando todos los partidos que el equipo ha jugado en el torneo.</span
      >
    </div>
  </div>
  <div v-else-if="show" class="text-container" :style="topLeft">
    <p class="modal-info-title">
      El menú te permite filtrar entre:
    </p>
    <br />
    <span class="modal-info-subtitle">Local/visitante:&nbsp;</span>
    <span class="modal-info-text"
      >Muestra datos considerando únicamente los partidos en los que el equipo ha jugado en la misma condición en la que
      se va a dar el partido (como local o visitante) en el torneo.</span
    >
    <br /><br />
    <span class="modal-info-subtitle">Todos los partidos:&nbsp;</span>
    <span class="modal-info-text"
      >Muestra datos considerando todos los partidos que el equipo ha jugado en el torneo.</span
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InfoTooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    coordinates: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
        };
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    isMobile() {
      return this.displayWidth < 769;
    },
    topLeft() {
      return { top: `${this.coordinates.y}px`, left: `${this.getX()}px` };
    },
  },
  methods: {
    getX() {
      if (this.coordinates.x + 20 + 288 > this.displayWidth) {
        return this.coordinates.x - 288 - 30;
      }
      return this.coordinates.x;
    },
  },
};
</script>

<style scoped lang="scss">
.text-container {
  position: fixed;
  width: 407px;
  z-index: 8;
  opacity: 1 !important;
  border-radius: 12px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.25);
  background-color: #132839;
  color: white;
  font-size: 0.8rem;
  font-family: Avenir-Medium, sans-serif;
  padding: 1rem 2.5rem;
  padding-bottom: 15px;
  text-align: left;
  @media screen and (max-width: 440px) {
    width: 288px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal-info-title {
  font-family: 'Roboto-Bold';
  font-size: 14px;
}
.modal-info-subtitle {
  font-family: 'Roboto-Bold';
  font-size: 13px;
}
.modal-info-text {
  font-family: 'Roboto-Light';
  font-size: 13px;
}
</style>
