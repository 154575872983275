<template>
  <div ref="radioPlayingAs" class="playing-as" :class="{ 'playing-as--mobile': isMobile }" :style="cupStyle">
    <div class="playing-as__fields">
      <div
        class="playing-as__fields__field playing-as__radio game-teams"
        :class="{ 'playing-as__fields__field--active': playingAs == 1 }"
        @click="
          playingAs = 1;
          analyticsPlayingAs();
        "
      >
        <input type="radio" :id="radioOneId" value="1" v-model="playingAs" checked />
        <div :for="radioOneId" class="radio-label">
          <div>
            <div class="img-container">
              <img :src="selectedGame.homeTeam.logo" />
            </div>
            <span class="team-info">
              <span v-if="isAcronymVisible" class="team-info__acronym">{{ selectedGame.homeTeam.acronym }}</span> Local
            </span>
          </div>
          <div class="slash">/</div>
          <div>
            <div class="img-container">
              <img :src="selectedGame.awayTeam.logo" />
            </div>
            <span class="team-info">
              <span v-if="isAcronymVisible" class="team-info__acronym">{{ selectedGame.awayTeam.acronym }}</span>
              Visitante
            </span>
          </div>
        </div>
      </div>
      <div
        class="playing-as__fields__field playing-as__radio all"
        :class="{ 'playing-as__fields__field--active': playingAs == 2 }"
        @click="
          playingAs = 2;
          analyticsPlayingAs();
        "
      >
        <input type="radio" :id="radioTwoId" value="2" v-model="playingAs" />
        <div class="radio-label" :for="radioTwoId">Todos</div>
      </div>
    </div>
    <InfoTooltip
      :show="showInfoTooltip"
      :coordinates="infoTooltipCoordinates"
      :text="infoTooltipText"
      @overlay-click="showInfoTooltip = false"
    />
    <div v-if="!isLastClashes || isMobile" class="playing-as__info" ref="questionIconRadioPlayingAs">
      ?
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import InfoTooltip from '@/components/Sections/GolTipster-v2/General/InfoTooltip';
import { infoMessages } from '@/utils/3pv';
import { RightBetLocality } from '@/utils/analytics';

export default {
  name: 'RadioPlayingAs',
  components: {
    InfoTooltip,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isQuestionVisible: {
      type: Boolean,
      default: true,
    },
    isLastClashes: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playingAs: 1,
      radioOneId: `r1${Date.now()}`,
      radioTwoId: `r2${Date.now()}`,
      showInfoTooltip: false,
      infoTooltipCoordinates: { x: 0, y: 0 },
      infoTooltipText: '',
      radioWidth: 720,
      radioResizeObserver: null,
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState('betsGeneral', ['filterPlayingAs', 'selectedGame']),
    ...mapState(['displayWidth']),
    isAcronymVisible() {
      return this.isMobile ? this.radioWidth > 320 : this.radioWidth > 350;
    },
    cupStyle() {
      const cupStyle = {};
      if (this.selectedGame.isCup) {
        cupStyle.display = 'none';
      }
      return cupStyle;
    },
  },
  watch: {
    filterPlayingAs: {
      immediate: true,
      handler(newValue) {
        this.playingAs = newValue;
      },
    },
    playingAs(newValue) {
      if (newValue !== this.filterPlayingAs) {
        this.setFilterPlayingAs(newValue);
      }
    },
    displayWidth(newValue) {
      this.setListeners(newValue);
    },
  },
  methods: {
    ...mapMutations('betsGeneral', ['setFilterPlayingAs']),
    setListeners(displayWidth) {
      if (displayWidth > 768) {
        if (this.isQuestionVisible) {
          this.setMouseOverListener();
        }
      } else if (this.isQuestionVisible) {
        this.setClickListener();
      }
    },
    setUpResizeObserver() {
      if (window.ResizeObserver && this.$refs.radioPlayingAs) {
        this.radioResizeObserver = new ResizeObserver(() => {
          this.radioWidth = this.$refs.radioPlayingAs.clientWidth;
        });
        this.radioResizeObserver.observe(this.$refs.radioPlayingAs);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    analyticsPlayingAs() {
      RightBetLocality({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        section: this.section,
      });
    },
    setMouseOverListener() {
      this.$refs.questionIconRadioPlayingAs.removeEventListener('click', this.onClick);
      this.$refs.questionIconRadioPlayingAs.addEventListener('mouseover', this.onMouseOver);
      this.$refs.questionIconRadioPlayingAs.addEventListener('mouseleave', this.onMouseLeave);
    },
    setClickListener() {
      this.$refs.questionIconRadioPlayingAs.removeEventListener('mouseover', this.onMouseOver);
      this.$refs.questionIconRadioPlayingAs.removeEventListener('mouseleave', this.onMouseLeave);
      this.$refs.questionIconRadioPlayingAs.addEventListener('click', this.onClick);
    },
    onMouseOver($event) {
      this.infoTooltipText = infoMessages[10];
      this.infoTooltipCoordinates = {
        x: $event.pageX + 16,
        y: $event.pageY,
      };
      this.showInfoTooltip = true;
    },
    onMouseLeave() {
      this.showInfoTooltip = false;
    },
    onClick() {
      this.infoTooltipText = infoMessages[10];
      this.showInfoTooltip = true;
    },
  },
  mounted() {
    this.setListeners(this.displayWidth);
    setTimeout(() => {
      this.setUpResizeObserver();
    }, 5000);
  },
};
</script>

<style scoped lang="scss">
$img-size: 1.5rem;
.playing-as {
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;

  &__fields {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #a4bbd0;
    padding: 0.1rem;

    &__field {
      padding: 0.2rem 0.8rem 0.15rem;
      cursor: pointer;

      & div {
        display: flex;
        align-items: center;
        font-family: Roboto, sans-serif;
        font-size: 0.75rem;
        color: #3b485c;
        cursor: pointer;

        & .slash {
          margin: 0 0.4rem 0 0.6rem;
        }

        & img {
          height: $img-size;
          width: $img-size;
        }
      }

      &:first-child {
        border-right: solid 1px #a4bbd0;
      }

      &--active {
        background-color: #d2f895;

        &.game-teams {
          border-radius: 8px 0 0 8px;
        }

        &.all {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  &--mobile {
    width: 100%;
    align-items: flex-start;

    & .playing-as {
      &__fields {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        &__field {
          width: 100%;
          text-align: left;
          & label {
          }

          &:first-child {
            border-right: unset;
          }

          &--active {
            &.game-teams {
              border-radius: 8px 8px 0 0;
            }

            &.all {
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }

      &__info {
        position: static;
        margin: 0.5rem;
      }
    }
  }

  &__info {
    font-size: 0.95rem;
    height: 1.4rem;
    width: 1.4rem;
    min-width: 1.4rem;
    border-radius: 50%;
    position: absolute;
    background-color: #9ca5ac;
    font-family: Roboto, sans-serif;
    color: white;
    right: -1.8rem;
    padding-top: 0.05rem;
    cursor: pointer;
  }

  &__radio {
    $color1: #f4f4f4;
    $color2: #82bf1d;

    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: $color1;
          border-radius: 100%;
          border: 1px solid darken($color1, 25%);
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          position: relative;
          margin-right: 0.75rem;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: $color2;
            box-shadow: inset 0 0 0 4px $color1;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $color2;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.team-info {
  white-space: nowrap;
  margin-left: 0.5em;
}

.img-container {
  height: $img-size;
  width: $img-size;
  min-height: $img-size;
  min-width: $img-size;
}
</style>
